import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import AnimateCSS from 'animate.css';
import 'animate.css/animate.min.css';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


Vue.use(AnimateCSS);
Vue.use(require('vue-moment'));
Vue.use(VueAxios, axios)