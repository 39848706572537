<template>
  <v-app>
    <v-main> <router-view></router-view></v-main>
  </v-app>
</template>

<style>
</style>

<script>
export default {
  name: "app",
  components: {},
   watch: {
      '$route' (to) {
        document.title = to.name + ' - Portughes Laundry and Dry Cleaning' || 'Portughes Laundry and Dry Cleaning'
      }
    },
};
</script>