import Vue from 'vue'
import VueRouter from 'vue-router'
import OutOfService from '@/views/Out-of-service.vue'
import Login from '@/views/login.vue'
import Main from '@/views/Main.vue'
import Order from '@/views/Order.vue'
import Confirmation from '@/views/Confirmation.vue'
import CollectionConfirmation from '@/views/Collection-confirmation.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation
  },
  {
    path: '/collection-confirmation',
    name: 'CollectionConfirmation',
    component: CollectionConfirmation
  },
  {
    path: '/out-of-service',
    name: 'Out Of Service',
    component: OutOfService
  },
]

const router = new VueRouter({
  routes
})

export default router
