
const url = "http://localhost:1801/";

export const kioskApi = {
    
    open(){
        return fetch(url+"open").then((response) => response.text())
    },

    close(){
        return fetch(url+"close").then((response) => response.text())
    },
    
    dropBag(){
        return fetch(url+"drop").then((response) => response.text())
    },

    unload(id_order){
        return fetch(url+"unload/"+id_order).then((response) => response.text())
    }
}


export default kioskApi