<template>
  <div>
    <v-container>
      <center>
        <h1>Please collect your garments</h1>
        <h4 class="mb-3">Thank your for using Portughes kiosk !</h4>
        <v-alert v-if="message" text outlined dense width="500" type="info"
          >This is a quick test for the message</v-alert
        >

        <v-img
          src="@/assets/img/clean_laundry.svg"
          width="250"
          class="mt-14 animate__animated animate__bounceIn"
        ></v-img>
      </center>
    </v-container>
    <NavigationBar :currentStep="currentStep" />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import CollectConfirmationAudio from "@/assets/audio/collectConfirmation.wav";
export default {
  name: "Main",
  components: {
    NavigationBar,
  },
  data() {
    return {
      currentStep: 2,
      message: null,
    };
  },
  methods: {
    playSound() {
      var audio = new Audio(CollectConfirmationAudio); // path to file
      audio.play();
    },
  },
  created() {
    this.playSound();
    setTimeout(function () {
      window.location.href = "#/out-of-service";
    }, 9000);
  },
};
</script>
