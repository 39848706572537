<template>
  <v-app class="primary--gradiant">
    <v-container>
      <center>
        <h1 class="white--text" v-if="isDisabled">Out of Service</h1>
        <h4 class="mb-3 white--text" v-if="isDisabled"> We're sorry Kiosk is out of service</h4>
        <v-img
          src="@/assets/img/logo_white.png"
          width="400"
          class="animate__animated animate__pulse animate__infinite"
        ></v-img>
      </center>
    </v-container>
  </v-app>
</template>

<script>
import api from "@/services/api";
export default {
    data() {
    return {
      isDisabled : false
    };
  },
  methods: {
    loginCheck() {
      this.loginInterval = setInterval(() => {
        this.login();
      }, 5000);
      return this.loginInterval;
    },
    login() {
      api({
        url: "/auth/",
        method: "POST",
        data: {
          username: process.env.VUE_APP_USERNAME,
          password: process.env.VUE_APP_PASSWORD,
        },
      })
        .then((res) => {
          if (res.data["success"]) {
            let records = res.data["records"];
            if (res.data["message"] == "LOGIN_SUCCESS") {
              localStorage.setItem("kiosk__token", records.token);
              clearInterval(this.loginInterval);
              this.isDisabled = false;
              this.$router.push({ path: "/" });
            }
          }
        })
        .catch((err) => {
          this.isDisabled = true;
          console.log(err);
        });
    },
  },
  created() {
    this.loginCheck();
  },
};
</script>

<style>
.v-application .primary--gradiant {
  background: linear-gradient(
    43deg,
    rgba(175, 12, 20, 1) 0%,
    rgba(204, 35, 42, 1) 41%,
    rgba(245, 46, 54, 1) 100%
  ) !important;
}
</style>