<template>
    <div>
        <v-container>
            <center>
                <h1>Welcome to Portughes Kiosk</h1>
                <h4 class="mb-3">Please Scan your QR Code</h4>
                <v-alert
                    :value="showAlert"
                    width="900"
                    transition="scale-transition"
                    type="error"
                    prominent
                >
                    {{ message }}</v-alert
                >

                <v-img
                    src="@/assets/img/qr_scan.png"
                    width="400"
                    class="animate__animated animate__pulse animate__infinite"
                ></v-img>
                <v-text-field
                    class="opacity-0"
                    ref="qrCode"
                    v-model="qrCodeData"
                    @change="loginFirst"
                    @blur="leaveFocus()"
                ></v-text-field>
            </center>
        </v-container>
        <NavigationBar :currentStep="currentStep" />
    </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import api from "@/services/api";
import ScanError from "@/assets/audio/scanError.wav";
import kioskApi from "@/services/kioskApi";

export default {
    name: "Main",
    components: {
        NavigationBar,
    },
    data() {
        return {
            currentStep: 1,
            qrCodeData: null,
            showAlert: null,
            message: null,
        };
    },
    methods: {
        leaveFocus() {
            this.$refs["qrCode"].focus();
        },
        playSound() {
            var audio = new Audio(ScanError);
            audio.play();
        },
        loginFirst() {
            api({
                url: "/auth/",
                method: "POST",
                data: {
                    username: process.env.VUE_APP_USERNAME,
                    password: process.env.VUE_APP_PASSWORD,
                },
            })
                .then((res) => {
                    if (res.data["success"]) {
                        let records = res.data["records"];
                        if (res.data["message"] == "LOGIN_SUCCESS") {
                            localStorage.setItem("kiosk__token", records.token);
                            this.checkQrCode();
                        }
                    }
                })
                .catch((err) => {
                    this.$router.push({ path: "out-of-service" });
                    console.log(err);
                });
        },
        checkQrCode() {
            api({
                url: "/order/scan-qr-code/",
                method: "POST",
                data: { qr_code: this.qrCodeData },
            })
                .then((res) => {
                    if (res.data["success"]) {
                        let records = res.data["records"];
                        console.log(records);
                        let screenData;
                        if (res.data["success"]) {
                            screenData = {
                                clientName: records.client.name,
                                orderId: records.id,
                                orderStatus: records.statusId,
                                numberOfItems: records.items_count,
                            };
                            localStorage.setItem(
                                "kiosk__screenData",
                                JSON.stringify(screenData)
                            );
                        }
                        kioskApi.open().then(() => {
                            this.$router.push({ path: "order" });
                        });
                    }
                })
                .catch((err) => {
                    this.showAlert = true;
                    this.message =
                        "This Order doesn't exist Please scan the QR code again , If the problem persists please call 21 44 44 44";
                    this.playSound();
                    this.qrCodeData = null;
                    setTimeout(() => {
                        this.showAlert = false;
                    }, 10000);
                    console.log(err);
                });
        },
    },
    mounted() {
        this.$refs["qrCode"].focus();
    },
};
</script>
<style>
.opacity-0 {
    opacity: 0 !important;
}
</style>
