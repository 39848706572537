<template>
    <div>
        <v-container>
            <center>
                <h1>Welcome, {{ clientName }}!</h1>
                <h3 class="mt-5" v-if="showCollectAnimation == false">
                    Your order
                    <span class="primary--text text-h5">{{ orderId }}</span> has
                    <span class="primary--text text-h5">{{
                        numberOfItems
                    }}</span>
                    Items
                </h3>
                <h3
                    class="mt-5 animate__animated animate__pulse animate__infinite"
                    v-else
                >
                    We are preparing your order
                </h3>

                <v-alert
                    :value="showAlert"
                    width="900"
                    transition="scale-transition"
                    type="error"
                    prominent
                >
                    {{ message }}</v-alert
                >

                <!--Collect order-->
                <template v-if="orderStatus == 495">
                    <!--<v-btn
            color="primary"
            depressed
            x-large
            rounded
            class="mt-10 animate__animated animate__pulse animate__infinite"
            @click="(showCollectAnimation = true), collectOrder()"
            v-if="showCollectAnimation == false"
          >
            <v-icon>mdi-hanger</v-icon> Collect order
          </v-btn>-->

                    <div
                        class="mt-10 animate__animated animate__jackInTheBox"
                        v-if="showCollectAnimation == true"
                    >
                        <v-progress-circular
                            indeterminate
                            color="red"
                            size="300"
                        >
                            <v-img
                                src="@/assets/img/unload.svg"
                                width="150"
                                class="animate__animated animate__pulse animate__infinite"
                            ></v-img>
                        </v-progress-circular>
                    </div>
                </template>
                <!--/Collect order-->

                <!--Drop in order-->
                <template v-if="orderStatus == 5">
                    <!--<v-btn
            color="primary"
            depressed
            x-large
            rounded
            class="mt-10 animate__animated animate__pulse animate__infinite"
            @click="(showBarcodeStep = true), takeBag()"
            v-if="!showBarcodeStep"
          >
            <v-icon>mdi-shopping</v-icon> Take the bag
          </v-btn>-->
                    <div
                        class="mt-10"
                        v-if="showBarcodeStep && showOpenDoorStep != true"
                    >
                        <v-img src="@/assets/img/bag.svg" width="150"></v-img>
                        <h3 class="animate__animated animate__slideInUp mt-5">
                            Put items in the bag and scan the Barcode
                        </h3>
                        <p
                            class="
                justify-center
                countdown__timer
                animate__animated animate__heartBeat animate__infinite
              "
                        >
                            {{ countDownBracodeScanner }}
                        </p>

                        <v-text-field
                            class="opacity-0"
                            ref="barCode"
                            v-model="barcodeData"
                            @change="checkBarCode"
                            @blur="leaveFocus()"
                        ></v-text-field>
                    </div>
                    <div class="mt-10" v-if="showOpenDoorStep">
                        <v-img
                            src="@/assets/img/door.svg"
                            width="100"
                            class="animate__animated animate__pulse animate__infinite"
                        ></v-img>
                        <h4 class="animate__animated animate__slideInUp mt-5">
                            Door is opened! Please put the bag in the door
                        </h4>
                        <h5 class="mt-5">The Kiosk Door Will be closed in</h5>
                        <p
                            class="
                justify-center
                countdown__timer
                animate__animated animate__heartBeat animate__infinite
              "
                            v-if="countDownCloseDoor != 0"
                        >
                            {{ countDownCloseDoor }}
                        </p>
                        <v-btn
                            v-if="
                                countDownCloseDoor < 20 &&
                                    countDownCloseDoor != 0
                            "
                            rounded
                            color="primary"
                            elevation="0"
                            @click="increaseTimer()"
                            >Wait More</v-btn
                        >
                    </div>

                    <div class="mt-10" v-if="showWrongBagStep">
                        <h4 class="animate__animated animate__slideInUp mt-5">
                            Wrong bag! Please, try again!
                        </h4>
                    </div>
                </template>
            </center>
        </v-container>
        <NavigationBar :currentStep="currentStep" />
    </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import api from "@/services/api";
import kioskApi from "@/services/kioskApi";

export default {
    name: "Order",
    components: {
        NavigationBar,
    },
    data() {
        return {
            currentStep: 2,
            clientName: null,
            orderId: null,
            numberOfItems: null,
            showBarcodeStep: null,
            showCollectAnimation: false,
            showOpenDoorStep: false,
            showWrongBagStep: false,
            barcodeData: null,
            showAlert: null,
            message: null,
            closeDoor: null,
            countDownCloseDoor: 60,
            countDownBracodeScanner: 60,
        };
    },

    methods: {
        leaveFocus() {
            this.$refs["barCode"].focus();
        },
        checkBarCode() {
            api({
                url: `/order/${this.orderId}/scan-bag-barcode`,
                method: "POST",
                data: {
                    bag_barcode: this.barcodeData,
                },
            }).then((res) => {
                if (res.data["success"]) {
                    this.countDownBracodeScanner = -1;
                    kioskApi.open().then(() => {
                        this.showOpenDoorStep = true;
                        this.showWrongBagStep = false;
                        this.countDownTimer();
                    });
                } else {
                    this.showWrongBagStep = true;
                    this.barcodeData = null;
                }
            });
        },

        takeBag() {
            kioskApi.dropBag().then(() => {
                this.showBarcodeStep = true;
                this.$nextTick(() => {
                    this.$refs["barCode"].focus();
                    this.countDownBracode();
                });
            });
        },

        collectOrder() {
            this.showCollectAnimation = true;
            kioskApi.unload(this.orderId).then((data) => {
                if (data == "unloadend") {
                    api({
                        url: `/order/${this.orderId}/collected`,
                        method: "PUT",
                    }).then((res) => {
                        if (res.data["success"]) {
                            api({
                                url: `/conveyor/order/${this.orderId}/slot`,
                                method: "PUT",
                            }).then((res) => {
                                if (res.data["success"]) {
                                    setTimeout(function() {
                                        window.location.href =
                                            "#/collection-confirmation";
                                    }, 1000);
                                }
                            });
                        }
                    });
                }
            });
        },

        submitBag() {
            api({
                url: `/order/${this.orderId}/submitted`,
                method: "PUT",
            }).then((res) => {
                if (res.data["success"]) {
                    this.$router.push({
                        name: "Confirmation",
                        params: { door: this.closeDoor },
                    });
                }
            });
        },

        countDownTimer() {
            var vm = this;
            var timer = setInterval(function() {
                vm.countDownCloseDoor = vm.countDownCloseDoor - 1;

                if (vm.countDownCloseDoor <= 0) {
                    clearInterval(timer);
                }
            }, 1000);
        },
        increaseTimer() {
            this.countDownCloseDoor = this.countDownCloseDoor + 10;
        },

        countDownBracode() {
            var vm = this;
            var timer = setInterval(function() {
                vm.countDownBracodeScanner = vm.countDownBracodeScanner - 1;

                if (vm.countDownBracodeScanner <= 0) {
                    clearInterval(timer);
                }
            }, 1000);
        },
    },

    created() {
        let screenData = JSON.parse(localStorage.getItem("kiosk__screenData"));
        this.clientName = screenData.clientName;
        this.orderId = screenData.orderId;
        this.orderStatus = screenData.orderStatus;
        this.numberOfItems = screenData.numberOfItems;
        if (this.orderStatus == 5) {
            this.takeBag();
        }
        if (this.orderStatus == 495) {
            this.collectOrder();
        }
    },

    mounted() {
        if (this.orderStatus == 5) {
            this.$refs["barCode"].focus();
        }
    },
    watch: {
        countDownCloseDoor: function(val) {
            if (val == 0) {
                this.submitBag();
            }
        },
        countDownBracodeScanner: function(val) {
            if (val == 0) {
                this.$router.push({
                    name: "Main",
                });
            }
        },
    },
};
</script>
<style>
.opacity-0 {
    opacity: 0 !important;
}
.countdown__timer {
    font-size: 72px;
}
</style>
