<template>
  <div>
    <v-container>
      <center>
        <h1>Your Order Has been Received</h1>
        {{$route.params.door}}
        <h4 class="mb-3">We will call you for the delivery</h4>
        <v-alert v-if="message" text outlined dense width="500" type="info"
          >This is a quick test for the message</v-alert
        >

        <v-img
          src="@/assets/img/confirm.svg"
          width="250"
          class="mt-14 animate__animated animate__bounceIn"
        ></v-img>
      </center>
    </v-container>
    <NavigationBar :currentStep="currentStep" />
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import ConirmationAudio from "@/assets/audio/confirmation.wav";
import kioskApi from "@/services/kioskApi";

export default {
  name: "Main",
  components: {
    NavigationBar,
  },
  data() {
    return {
      currentStep: 3,
      message: null,
    };
  },
  methods: {
    playSound() {
      var audio = new Audio(ConirmationAudio); // path to file
      audio.play();
    },
  },
  created() {
    this.playSound();
    kioskApi.close();
    setTimeout(function () {
           window.location.href = "#/out-of-service";
    }, 10000 );
  },
};
</script>
