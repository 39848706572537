<template>
  <v-app class="primary--gradiant">
    <v-container>
      <center>
        <v-img
          src="@/assets/img/logo_white.png"
          width="400"
          class="animate__animated animate__pulse animate__infinite"
        ></v-img>
      </center>
    </v-container>
  </v-app>
</template>

<script>
import api from "@/services/api";
export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods: {
    login() {
      api({
        url: "/auth/",
        method: "POST",
        data: { username: this.username, password: this.password },
      })
        .then((res) => {
          if (res.data["success"]) {
            let records = res.data["records"];
            if (res.data["message"] == "LOGIN_SUCCESS") {
              localStorage.setItem("kiosk__token", records.token);
              this.$router.push({ path: "main" });
            }
          }
        })
        .catch((err) => {
          this.$router.push({ path: "out-of-service" });
          console.log(err);
        });
    },
  },
  created() {
    this.username= process.env.VUE_APP_USERNAME;
    this.password= process.env.VUE_APP_PASSWORD;
    if (localStorage.getItem("kiosk__token")) {
      this.$router.push({ path: "main" });
    } else {
      this.login();
    }
  },
};
</script>

<style>
</style>