<template>
  <v-bottom-navigation dark fixed height="80">
    <v-row no-gutters>
      <v-col class="ma-0 pa-0" md="1">
        <v-img src="@/assets/img/logo_red.png" height="80" width="80"></v-img>
      </v-col>
      <v-col class="ma-0 pa-0 mt-2" md="2">
        <h2 class="ml-1">{{time}}</h2>
        <p class="ml-1">{{date}}</p>
      </v-col>
      <v-col class="ma-0 pa-0" md="3"></v-col>
      <v-col class="ma-0 pa-0 mt-2" md="6">
        <v-stepper class="elevation-0" v-model="currentStep">
          <v-stepper-header>
            <div v-for="step in steps" :key="step.number">
              <v-stepper-step :step="step.number">
                {{ step.name }}
              </v-stepper-step>

              <v-divider></v-divider>
            </div>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
  </v-bottom-navigation>
</template>

<script>
import moment from 'moment';
export default {
  props: ["currentStep"],
  data() {
    return {
      steps: [
        { number: 1, name: "Scan QR Code" },
        { number: 2, name: "Drop in items or collect them!" },
        { number: 3, name: "Confirmation" },
      ],
      date: null,
      time: null,
      
    };
  },
  methods: {
    updateCurrentTime() {
      this.time = moment().format("LTS");
      this.date = moment().format("dddd, MMMM Do");
    }

  },

  created() {
    this.time = moment().format("LTS");
    this.date = moment().format("dddd, MMMM Do");
    setInterval(() => this.updateCurrentTime(), 1 * 1000);
  }

};
</script>

<style>
</style>