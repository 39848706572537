import axios from 'axios'


const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
    },
})

//api.defaults.timeout = 5000;
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem("kiosk__token");
        if (token) {
            config.headers.common["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            console.log(error.response.status)
            return Promise.reject(error.response);
        }
    }
);

export default api